import React, { FC } from 'react'

import styles from './styles.module.scss'

const NotFoundPage: FC = () => (
  <div className={ styles.notFound }>
    <p>Page not found</p>
  </div>
)

export default NotFoundPage
