import React, { FC } from "react";
// import { Fade, Grid } from '@material-ui/core'
import { Grid, Fade, Tooltip, Button } from "@material-ui/core";
import {
  IConsentInfoModel,
  IConsentInfoSingleModel,
} from "models/consentInfoModel";

import cn from "classnames";
import styles from "./styles.module.scss";

interface IPatientStatusProps {
  patientStatus: IConsentInfoSingleModel;
  header: string;
}

const PatientStatus: FC<IPatientStatusProps> = ({ patientStatus, header }) => {
  const {
    initial1,
    initial2,
    initial3,
    initial4,
    initial5,
    initial6,
    initial7,
    initial8,
    patientFirstname,
    patientMiddlename,
    patientLastname,
    patientDob,
    patientSignature,
    submitted,
  } = patientStatus.statusRec;

  const statusClassNames = cn(styles.statusText, {
    [styles.submitted]: submitted,
  });

  return (
    <Fade in={patientStatus.statusMessage !== "aa"} timeout={500}>
      <div className={styles.patientStatus}>
        <p className={styles.formStatus}>{header} status:</p>
        <p
          className={statusClassNames}
          dangerouslySetInnerHTML={{ __html: patientStatus.statusMessage }}
        />
        <Grid container>
          {header === "Feasibility Consent" && (
            <Grid item xs={12} sm={12}>
              <p className={styles.lineItem}>
                <span>
                  <b>Initials against the 8 consent points:</b> - the patient
                  needs all 8 boxes initialled with the same initials
                </span>
              </p>
            </Grid>
          )}

          {initial1 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 1: </span>
                <span className={styles.value}>{initial1}</span>
              </p>
            </Grid>
          )}
          {initial2 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 2: </span>
                <span className={styles.value}>{initial2}</span>
              </p>
            </Grid>
          )}
          {initial3 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 3: </span>
                <span className={styles.value}>{initial3}</span>
              </p>
            </Grid>
          )}
          {initial4 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 4: </span>
                <span className={styles.value}>{initial4}</span>
              </p>
            </Grid>
          )}
          {initial5 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 5: </span>
                <span className={styles.value}>{initial5}</span>
              </p>
            </Grid>
          )}
          {initial6 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 6: </span>
                <span className={styles.value}>{initial6}</span>
              </p>
            </Grid>
          )}
          {initial7 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 7: </span>
                <span className={styles.value}>{initial7}</span>
              </p>
            </Grid>
          )}
          {initial8 && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Initial 8: </span>
                <span className={styles.value}>{initial8}</span>
              </p>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <p className={styles.lineItem}>
              <span>
                <b>Patient details:</b>
              </span>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={styles.lineItem}>
              <span>First Name: </span>
              <span className={styles.value}>{patientFirstname}</span>
            </p>
          </Grid>
          {patientMiddlename && (
            <Grid item xs={12} sm={6}>
              <p className={styles.lineItem}>
                <span>Middle Name:</span>
                <span className={styles.value}>{patientMiddlename}</span>
              </p>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <p className={styles.lineItem}>
              <span>Last Name: </span>
              <span className={styles.value}>{patientLastname}</span>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={styles.lineItem}>
              <span>Date of Birth: </span>
              <span className={styles.value}>{patientDob}</span>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className={styles.lineItem}>
              <span>Signature: </span>
              <span className={styles.value}>
                {patientSignature ? "Signed" : ""}
              </span>
            </p>
          </Grid>
        </Grid>
        {!submitted && <Grid item xs={12} sm={6}></Grid>}
      </div>
    </Fade>
  );
};

export default PatientStatus;
