import {
  createBrowserHistory, BrowserHistoryBuildOptions, History,
} from 'history'

import Config from 'config.json'
import { IHistoryService } from 'actions/layerInterfaces'

const buildHistoryOptions: BrowserHistoryBuildOptions = {
  basename: Config.baseUrl,
}

class HistoryService implements IHistoryService {
  history: History<History> = createBrowserHistory()

  getHistory = () => this.history

  push = (route: string) => {
    this.history.push(route)
  }
}

export default new HistoryService()
