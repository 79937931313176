import React, { FC } from 'react'
import { Router } from 'react-router-dom'
import Actions from 'actions'
import AppRouter from 'view/routers/AppRouter'
import styles from './index.module.scss'

const App: FC = () => (
  <Router history={ Actions.HistoryService.getHistory() }>
    <div className={ styles.appContainer }>
      <AppRouter />
    </div>
  </Router>
)


export default App
