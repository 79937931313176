
import connector from './connector'
import IAppCore from './interfaces'
import APIActions from './API'

class AppCore implements IAppCore {
  connector = connector

  APIActions = APIActions
}

export default new AppCore()
