import { IDoctorScreenService } from "actions/layerInterfaces";
import delivery from "delivery";

import { ProtoBloc } from "actions/ProtoBloc";
import { IDoctorScreenState } from "./stateModel";

const defaultAuthState: IDoctorScreenState = {
  isLoading: true,
  consentId: "",
  consentId2: "",
  consentStart: "",
  consentStart2: "",
  zoomStart: "",
  doctorName: "",
  patientName: "",
  errorMessage: null,
  consentInfo: {
    consent1: {
      pdf: null,
      statusMessage: "",
      statusRec: {
        initial1:"",
        initial2:"",
        initial3:"",
        initial4:"",
        initial5:"",
        initial6:"",
        initial7:"",
        initial8:"",
        patientDob: "",
        patientFirstname: "",
        patientMiddlename: "",
        patientLastname: "",
        patientSignature: "",
        submitted: false,
      },
      allowed: false,
      display: true
    },
    consent2: {
      pdf: null,
      statusMessage: "",
      statusRec: {
        initial1:"",
        initial2:"",
        initial3:"",
        initial4:"",
        initial5:"",
        initial6:"",
        initial7:"",
        initial8:"",
        patientDob: "",
        patientFirstname: "",
        patientMiddlename: "",
        patientLastname: "",
        patientSignature: "",
        submitted: false,
      },
      allowed: false,
      display: true
    },

  },
  consentHtml: "",
  consentHtml2: "",
  patientFirstname: "",
  patientLastname: "",
  patientDob: "",
  consentLabel1: "",
  consentLabel2: ""
};

class DoctorScreenBloc
  extends ProtoBloc<IDoctorScreenState>
  implements IDoctorScreenService {
  constructor() {
    super(defaultAuthState);
  }

  getDoctorData = async (id: string) => {
    const { value, error } = await delivery.APIActions.getDoctorData(id);

    if (error) {
      this.pushState({
        ...this.state,
        isLoading: false,
        errorMessage: "Oops something went wrong...",
      });

      return;
    }

    if (value) {
      this.pushState({
        ...this.state,
        isLoading: false,
        consentId: value.consent_id,
        consentId2: value.consent_id2,
        consentStart: value.consent_start,
        consentStart2: value.consent_start2,
        zoomStart: value.zoom_start,
        doctorName: value.doctor_name,
        patientName: value.patient_name,
        consentHtml: value.consent_html,
        consentHtml2: value.consent_html2,
        patientFirstname: value.patient_firstname,
        patientLastname: value.patient_surname,
        patientDob: value.patient_dob,
        consentLabel1: value.consent_label1,
        consentLabel2: value.consent_label2,
      });
    }
  };

  checkConsentStatus = async (meetingId: string) => {
    const { value, error } = await delivery.APIActions.getConsentStatus(
      meetingId
    );

    if (error) {
      console.warn("something went wrong");

      return;
    }

    if (value) {
      this.pushState({
        ...this.state,
        consentInfo: value,
      });

      if (!value.consent1.statusRec.submitted || !value.consent2.statusRec.submitted) {
        setTimeout(() => {
          this.checkConsentStatus(meetingId);
        }, 2000);
      }
    }
  };
}

export default new DoctorScreenBloc();
