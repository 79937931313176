import React, { FC, useState, useRef, useEffect } from "react";

import { Button, Fade } from "@material-ui/core";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import styles from "./styles.module.scss";

interface IDoctorViewComponentProps {
  pdf: string | null;
  submitted: boolean;
}

const PDFDocument: FC<IDoctorViewComponentProps> = ({ pdf, submitted }) => {
  const pageCanvas = useRef<HTMLDivElement>(null);
  const [numberOfPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    if (pageCanvas.current) {
      setPageWidth(pageCanvas.current.clientWidth);
    }
  }, []);

  const onDocumentLoadSuccess = (num: number) => {
    console.log(pdf);
    setNumPages(num);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  return (
    <Fade in={submitted} timeout={500}>
      <>
        <div ref={pageCanvas}>
          <Document
            file={pdf}
            onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
            className={styles.pdf}
          >
            <Page pageNumber={pageNumber} width={pageWidth} />
          </Document>
        </div>
        <div>
          <p>
            Page {pageNumber || (numberOfPages ? 1 : "--")} of{" "}
            {numberOfPages || "--"}
          </p>
          <Button
            type="button"
            disabled={pageNumber <= 1}
            onClick={() => changePage(-1)}
          >
            Previous
          </Button>
          <Button
            type="button"
            disabled={pageNumber >= numberOfPages}
            onClick={() => changePage(1)}
          >
            Next
          </Button>
        </div>
      </>
    </Fade>
  );
};

export default PDFDocument;
