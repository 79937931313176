import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import { browserHistory } from "react-router";
import Loader from 'view/screens/Components/Loader'
import { getParam } from 'common/lib'

import { Fade, Button } from '@material-ui/core'
import { IFormDataModel } from 'models/formDataModel'
import ConsentForm from './ConsentForm'

import styles from './styles.module.scss'

interface IConsentViewComponentProps {
  isLoading: boolean;
  isValid: boolean;
  isCompleted: boolean | null;
  zoomJoin: string;
  failedValidation: boolean | null,
  consentHtml: string;
  errorMessage: string | null;
  forwardUrl: string | null;
  getConsentData: (id: string) => void;
  updateConsentStatus: (formData: IFormDataModel, submitted?: boolean) => void;
  validate: (formData: IFormDataModel) => void;
}

const ConsentViewComponent: FC<IConsentViewComponentProps> = ({
  isLoading,
  isValid,
  isCompleted,
  zoomJoin,
  failedValidation,
  consentHtml,
  errorMessage,
  forwardUrl,
  getConsentData,
  updateConsentStatus,
  validate,
}) => {
  const history = useHistory()

  useEffect(() => {
    const currentUrl = window.location.href
    const consentId = getParam(currentUrl, 'consent_id')

    if (consentId) {
      getConsentData(consentId)
      console.log('forward_url', forwardUrl)

    } else {
      history.push('/404')
    }
  }, [])

  useEffect(() => {
    const currentUrl = window.location.href
    if (isValid && zoomJoin == '') {

      if (currentUrl.includes('staging') || currentUrl.includes('dev')) {
        window.location.href = 'https://staging2.lvlhealth.co.uk/post-medical-questionnaire/'
      } else {
        window.location.href = 'https://lvlhealth.co.uk/post-medical-questionnaire/'
      }

    }
  }, [isValid])

  useEffect(() => {

    console.log('>>forwardUrl is ', forwardUrl)
    if (forwardUrl && forwardUrl != 'no doctor') {
      console.log('>>>forward to', forwardUrl)
      window.location.href = forwardUrl
    }

  }, [forwardUrl])

  const showForm = !errorMessage && !isLoading

  return (
    <>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Fade
          in={!isLoading}
          timeout={500}
        >
          <>
            {errorMessage && (
              <div className={styles.errorContainer}>
                <p>{errorMessage}</p>
              </div>
            )}
            {isCompleted && (
              <div className={styles.errorContainer}>
                <p>Form has been submitted</p>
              </div>
            )}
            {showForm && (
              <div className={styles.container}>
                <h1>Consent Form</h1>
                {!isValid ? (
                  <>
                    <p dangerouslySetInnerHTML={{ __html: consentHtml }} />
                    <ConsentForm
                      updateConsentStatus={updateConsentStatus}
                      validate={validate}
                    />
                    <Fade
                      in={!!failedValidation}
                      timeout={500}
                    >
                      <p className={styles.error}>Details are not valid, please try again.</p>
                    </Fade>
                  </>
                ) : (
                  <>
                    <Fade
                      in={isValid}
                      timeout={500}
                    >
                      {(zoomJoin != '') ? (
                        <>
                          <p>Consent form completed. You can join the meeting by clicking the button below.</p>
                          <Button
                            variant="contained"
                            color="primary"
                            href={zoomJoin}
                            target="_blank"
                          >
                            Join Meeting
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* <p>Thank you for posting your medical details.

                          </p>
                          <p>
                            We will evaluate your case at the next MDT – you will receive an email shortly afterwards letting you know the next steps.
                          </p>

                          <p>

                            Have a look at this site to view our onboarding process.</p> */}
                        </>
                      )

                      }


                    </Fade>
                  </>
                )}
              </div>
            )}
          </>
        </Fade>
      )}
    </>
  )
}

export default ConsentViewComponent
