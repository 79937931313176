import React, { FC } from 'react'
import { Fade, Grid } from '@material-ui/core'

import { IPatientInfoModel } from 'models/patientInfoModel'

import cn from 'classnames'
import styles from './styles.module.scss'


const PatientData: FC<IPatientInfoModel> = ({
  patientFirstname, patientLastname, patientDob
}) => {



  return (

    <div className={styles.patientStatus}>
      {/* <p className={ styles.formStatus }>Current Form Status:</p>
        <p
          className={ statusClassNames }
          dangerouslySetInnerHTML={ { __html: patientStatus.statusMessage } }
        /> */}
      <p className={styles.formStatus}>This is the data we hold on the patient:</p>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <p className={styles.lineItem}>
            <span>First Name: </span>
            <span className={styles.value}>{patientFirstname}</span>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <p className={styles.lineItem}>
            <span>Last Name: </span>
            <span className={styles.value}>{patientLastname}</span>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <p className={styles.lineItem}>
            <span>Date of Birth: </span>
            <span className={styles.value}>{patientDob}</span>
          </p>
        </Grid>

      </Grid>
    </div>

  )
}

export default PatientData
