import { IActions } from 'actions/layerInterfaces'
import DoctorScreenBloc from 'actions/blocs/DoctorScreenBloc'
import ConsentScreenBloc from 'actions/blocs/ConsentScreenBloc'
import HistoryBloc from 'actions/blocs/HistoryService'

class Actions implements IActions {
  DoctorScreenService = DoctorScreenBloc

  ConsentScreenService = ConsentScreenBloc

  HistoryService = HistoryBloc
}

export default new Actions()
