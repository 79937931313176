import React, { FC, ChangeEvent, useRef } from "react";

import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { IFormDataModel } from "models/formDataModel";

import styles from "./styles.module.scss";

interface IConsentFormProps {
  updateConsentStatus: (FormData: IFormDataModel, submitted?: boolean) => void;
  validate: (FormData: IFormDataModel) => void;
}
var buttonDebounce = false;
const ConsentFormComponent: FC<IConsentFormProps> = ({
  updateConsentStatus,
  validate,
}) => {
  const sigCanvas = useRef<SignatureCanvas>(null);

  const formik = useFormik({
    initialValues: {
      initial1: "",
      initial2: "",
      initial3: "",
      initial4: "",
      initial5: "",
      initial6: "",
      initial7: "",
      initial8: "",
      firstName: "",
      middleName: "",
      lastName: "",
      date: null,
      checked: null,
      signature: null,
    },
    validationSchema: Yup.object().shape({
      initial1: Yup.string().required("Required"),
      initial2: Yup.string().required("Required"),
      initial3: Yup.string().required("Required"),
      initial4: Yup.string().required("Required"),
      initial5: Yup.string().required("Required"),
      initial6: Yup.string().required("Required"),
      initial7: Yup.string().required("Required"),
      initial8: Yup.string().required("Required"),
      firstName: Yup.string().required("Required"),
      // middleName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      // date: Yup.string().required("Required"),
      checked: Yup.boolean().required("Required"),
      signature: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // the button could be pressed a lot - so disable for 3 seconds to allow the back-end to process
      // the data
      if (buttonDebounce == false) {
        buttonDebounce = true;
        console.log("bounce", buttonDebounce, disableButton);
        setTimeout(function () {
          buttonDebounce = false;
        }, 5000);
        validate(values);
      } else {
        console.log("debouncing");
      }
    },
  });

  const handleChange = (name: string, value: string | boolean | null) => {
    if (value && typeof value == "string" && name !== "signature") {
      formik.setFieldValue(name, value.toUpperCase());
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleCanvasClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      handleChange("signature", "");
    }
  };

  const handleSignature = () => {
    if (sigCanvas.current) {
      const signature = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      handleChange("signature", signature);
      updateConsentStatus(formik.values);
    }
  };

  const disableButton = !(formik.isValid && formik.dirty);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <p>
              1. You are a patient receiving treatment at the clinic and you
              have been asked to participate in this feasibility study.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-1"
              name="initial-1"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial1", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              2. I have received, as part of being asked if I want to join this
              feasibility study the Final Version 1.0 of the Feasibility Study
              Patient Information Sheet dated 17th August 2021 which I have read
              and understood, for the above-mentioned research. I have had the
              opportunity to ask questions, I have had these answered to my
              satisfaction, and I have had enough time to consider my
              participation.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-2"
              name="initial-2"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial2}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial2", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              3. I understand that I have signed this informed consent form, to
              have my data collected in relation to the feasibility study.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-3"
              name="initial-3"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial3}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial3", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              4. I understand that taking part is voluntary, and that I am free
              to withdraw at any time without giving a reason, and without my
              medical care or legal rights being affected.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-4"
              name="initial-4"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial4}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial4", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              5. I understand that relevant sections of my medical notes and
              data collected may be looked at by individuals from Harley Street
              (CPC), independent auditors, its representatives, and regulatory
              authorities where it is relevant to my taking part in this
              research. By signing this consent form, I give permission for
              these individuals to access my medical records.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-5"
              name="initial-5"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial5}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial5", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              6. I understand that data protection laws differ in nonEEA
              countries and if I do not want my anonymised data to be sent
              outside of the EEA, I will not be able to take part in this study.
              I agree that my samples and anonymised data can be sent outside of
              the EEA.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-6"
              name="initial-6"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial6}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial6", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              7. I assign all rights in my data and materials to Harley Street
              (CPC) and acknowledge that Harley Street (CPC) will own such data
              and materials and the rights to them.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-7"
              name="initial-7"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial7}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial7", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <p>
              8. I agree to the arrangements detailed in the Patient Information
              sheet.
            </p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="initial-8"
              name="initial-8"
              label="Initials"
              type="text"
              required
              variant="standard"
              value={formik.values.initial8}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("initial8", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <p>
            <b>Please indicate your consent by completing the box below</b>
          </p>
        </Grid>
        <Grid item xs={12} sm={12}>
          <p>
            <b>THIS PART TO BE COMPLETED BY THE PARTICIPANT</b>
          </p>
        </Grid>
        <div className={styles.signaturePad}>
          <p>Signature:</p>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ height: 400, className: styles.sigCanvas }}
            onEnd={handleSignature}
          />
          <Button
            className={styles.clearButton}
            variant="outlined"
            color="primary"
            onClick={handleCanvasClear}
          >
            Reset Signature
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              id="first-name"
              name="first-name"
              label="Participant's First Name"
              type="text"
              required
              variant="standard"
              value={formik.values.firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("firstName", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              autoComplete="off"
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="middle-name"
              name="middle-name"
              label="Middle Name"
              variant="standard"
              autoComplete="off"
              type="text"
              value={formik.values.middleName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("middleName", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="last-name"
              name="last-name"
              label="Last Name"
              variant="standard"
              autoComplete="off"
              type="text"
              required
              value={formik.values.lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("lastName", e?.target.value)
              }
              onFocus={() => updateConsentStatus(formik.values)}
              onBlur={() => updateConsentStatus(formik.values)}
              classes={{
                root: styles.textField,
              }}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <>
              <DatePicker
                id="date"
                name="date"
                label="Date of Birth"
                autoComplete="off"
                disableFuture
                value={formik.values.date}
                openTo="year"
                clearable
                onChange={(date) =>
                  handleChange("date", moment(date).format("YYYY-MM-DD"))
                }
                onFocus={() => updateConsentStatus(formik.values)}
                onBlur={() => updateConsentStatus(formik.values)}
                format="MMMM Do YYYY"
                className={styles.textField}
              />
            </>
          </Grid> */}
        </Grid>
        <div />

        <FormControlLabel
          control={
            <Checkbox
              onFocus={() => updateConsentStatus(formik.values)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("checked", e.target.checked)
              }
            />
          }
          label="Confirm Your Consent"
          className={styles.checkBox}
        />
        <Button
          variant="contained"
          color="primary"
          className={styles.submitButton}
          type="submit"
          disabled={disableButton}
        >
          Submit
        </Button>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default ConsentFormComponent;
