import React, { FC } from "react";
import Actions from "actions";
import { useObservable } from "rxjs-hooks";
import DoctorViewComponent from "./component";

const DoctorViewContainer: FC = () => {
  const {
    isLoading,
    consentStart,
    consentStart2,
    zoomStart,
    doctorName,
    patientName,
    consentInfo,

    errorMessage,
    consentId,
    consentId2,
    consentHtml,
    consentHtml2,
    patientFirstname,
    patientLastname,
    patientDob,
    consentLabel1,
    consentLabel2
  } =
    useObservable(Actions.DoctorScreenService.getSubject) ||
    Actions.DoctorScreenService.getCurrentState();

  return (
    <DoctorViewComponent
      isLoading={isLoading}
      consentStart={consentStart}
      consentStart2={consentStart2}
      zoomStart={zoomStart}
      doctorName={doctorName}
      patientName={patientName}
      consentInfo={consentInfo}
      errorMessage={errorMessage}
      getDoctorData={Actions.DoctorScreenService.getDoctorData}
      checkConsentStatus={Actions.DoctorScreenService.checkConsentStatus}
      consentId={consentId}
      consentId2={consentId2}
      consentHtml={consentHtml}
      consentHtml2={consentHtml2}
      patientFirstname={patientFirstname}
      patientLastname={patientLastname}
      patientDob={patientDob}
      consentLabel1={consentLabel1}
      consentLabel2={consentLabel2}
    />
  );
};
export default DoctorViewContainer;
