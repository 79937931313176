import React, { FC, useRef, useState, useEffect } from "react";
import { Grid, Fade, Tooltip, Button } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ConsentViewComponent from "../../ConsentView/component";
import styles from "./styles.module.scss";
import { getParam } from "common/lib";
import Actions from "actions";
import { IConsentInfoModel } from "models/consentInfoModel";
interface IDoctorGreetingProps {
  consentStart: string;
  consentStart2: string;
  zoomStart: string;
  doctorName: string;
  patientName: string;
  consentId: string;
  consentId2: string;
  consentHtml: string;
  consentHtml2: string;
  patientFirstname: string;
  patientLastname: string;
  patientDob: string;
  consentInfo: IConsentInfoModel;
  consentLabel1: string;
  consentLabel2: string;
}

const DoctorGreeting: FC<IDoctorGreetingProps> = ({
  consentStart,
  consentStart2,
  zoomStart,
  doctorName,
  patientName,
  consentInfo,
  consentId,
  consentId2,
  consentHtml,
  consentHtml2,
  patientFirstname,
  patientLastname,
  patientDob,
  consentLabel1,
  consentLabel2,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showToolTip, updateToolTip] = useState(false);
  const [showToolTip2, updateToolTip2] = useState(false);

  const handleCopy = () => {
    updateToolTip(true);

    setTimeout(() => {
      updateToolTip(false);
    }, 2000);
  };
  const handleCopy2 = () => {
    updateToolTip2(true);

    setTimeout(() => {
      updateToolTip2(false);
    }, 2000);
  };
  // useEffect(() => {
  //   const currentUrl = window.location.href;
  //   // const consentId =
  //   //   "JpKysLU0yBiO2PmRGibXZ0ojm02WPcC186MFCJY88DufGEIbPoxF3PyYjdXSQg7NYbC6Ma3rZgWvWNZs.01-02";

  //   //  getParam(currentUrl, "consent_id");

  //   if (consentId) {
  //     var res = Actions.ConsentScreenService.getConsentData(consentId);
  //     updateConsentHtml(res);
  //   }
  // }, []);
  return (
    <Fade in timeout={500}>
      <div className={styles.doctorGreeting}>
        <h1>{`Hello ${doctorName}`}</h1>
        <p>{`Here are the details for your meeting with ${patientName}`}</p>

        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Button
              variant="contained"
              color="primary"
              href={zoomStart}
              target="_blank"
              fullWidth
            >
              Start Zoom
            </Button>
          </Grid>
          <Grid item sm={12}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* <Grid item sm={6} >
            <Tooltip open={showToolTip} title="Link copied to clip board">
              <CopyToClipboard text={consentStart} onCopy={handleCopy}>
                <Button
                  variant="contained"
                  color="primary"
                  ref={buttonRef}
                  fullWidth
                >
                  Start Consent 1
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </Grid> */}
          {consentLabel1 !== "" && (
            <>
              {consentInfo.consent1.allowed && (
                <Grid item sm={6}>
                  <Tooltip open={showToolTip} title="Link copied to clip board">
                    <CopyToClipboard text={consentStart} onCopy={handleCopy}>
                      <Button
                        variant="contained"
                        color="primary"
                        ref={buttonRef}
                        fullWidth
                      >
                        {consentLabel1}
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </Grid>
              )}
              {!consentInfo.consent1.allowed && (
                <Grid item sm={6}>
                  <Tooltip open={showToolTip} title="Link copied to clip board">
                    <CopyToClipboard text={consentStart} onCopy={handleCopy}>
                      <Button
                        variant="contained"
                        color="primary"
                        ref={buttonRef}
                        fullWidth
                        disabled
                      >
                        {consentLabel1}
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </Grid>
              )}
              <Grid item sm={6}>
                {consentInfo.consent1.statusRec.submitted && (
                  <p>Patient has submitted this consent</p>
                )}
              </Grid>
            </>
          )}
        </Grid>
        {consentLabel2 != "" && (
          <Grid container spacing={2}>
            {consentInfo.consent2.allowed && (
              <Grid item sm={6}>
                <Tooltip open={showToolTip2} title="Link copied to clip board">
                  <CopyToClipboard text={consentStart2} onCopy={handleCopy2}>
                    <Button
                      variant="contained"
                      color="primary"
                      ref={buttonRef}
                      fullWidth
                    >
                      {consentLabel2}
                    </Button>
                  </CopyToClipboard>
                </Tooltip>
              </Grid>
            )}
            {!consentInfo.consent2.allowed && (
              <Grid item sm={6}>
                <Tooltip open={showToolTip2} title="Link copied to clip board">
                  <CopyToClipboard text={consentStart2} onCopy={handleCopy2}>
                    <Button
                      variant="contained"
                      color="primary"
                      ref={buttonRef}
                      fullWidth
                      disabled
                    >
                      {consentLabel2}
                    </Button>
                  </CopyToClipboard>
                </Tooltip>
              </Grid>
            )}
            <Grid item sm={6}>
              {consentInfo.consent2.statusRec.submitted && (
                <p>Patient has submitted this consent</p>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </Fade>
  );
};

export default DoctorGreeting;
