import React, { FC } from 'react'
import Actions from 'actions'
import { useObservable } from 'rxjs-hooks'
import ConsentViewComponent from './component'

const ConsentViewContainer: FC = () => {
  const {
    isLoading,
    isValid,
    zoomJoin,
    failedValidation,
    consentHtml,
    errorMessage,
    completed,
    forwardUrl,
  } = useObservable(Actions.ConsentScreenService.getSubject) || Actions.ConsentScreenService.getCurrentState()

  return (
    <ConsentViewComponent
      isLoading={isLoading}
      isCompleted={completed}
      isValid={isValid}
      zoomJoin={zoomJoin}
      consentHtml={consentHtml}
      failedValidation={failedValidation}
      errorMessage={errorMessage}
      forwardUrl={forwardUrl}
      getConsentData={Actions.ConsentScreenService.getConsentData}
      updateConsentStatus={Actions.ConsentScreenService.updateConsentStatus}
      validate={Actions.ConsentScreenService.validate}
    />
  )
}
export default ConsentViewContainer
