const getParam = (url: string, param: string) => {
  const breakUrl = url.split('/')
  let paramToReturn: string = ''

  breakUrl.forEach((item) => {
    if (item.includes(param)) {
      const split = item.split('?')[1]
      const split2 = split.split('=')[1]
      paramToReturn = split2
    }
  })

  return paramToReturn.length > 0 ? paramToReturn : null
}

export { getParam }
